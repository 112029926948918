import { isPlatformSupported, isBrowser } from './common/utils';
import { patchAll } from './common/patching';
import { bootstrapMetrics } from './performance-monitoring/metrics';
var enabled = false;
export function bootstrap() {
  if (isPlatformSupported()) {
    patchAll();
    bootstrapMetrics();
    enabled = true;
  } else if (isBrowser) {
    console.log('[Elastic APM] platform is not supported!');
  }

  return enabled;
}