import { apmSymbol, patchMethod, XHR_SYNC, XHR_URL, XHR_METHOD, XHR_IGNORE } from './patch-utils';
import { scheduleMacroTask } from '../utils';
import { SCHEDULE, INVOKE, CLEAR, XMLHTTPREQUEST, ADD_EVENT_LISTENER_STR, REMOVE_EVENT_LISTENER_STR } from '../constants';
var XHR_TASK = apmSymbol('xhrTask');
var XHR_LISTENER = apmSymbol('xhrListener');
var XHR_SCHEDULED = apmSymbol('xhrScheduled');
export function patchXMLHttpRequest(callback) {
  var XMLHttpRequestPrototype = XMLHttpRequest.prototype;
  var oriAddListener = XMLHttpRequestPrototype[ADD_EVENT_LISTENER_STR];
  var oriRemoveListener = XMLHttpRequestPrototype[REMOVE_EVENT_LISTENER_STR];

  if (!oriAddListener) {
    var XMLHttpRequestEventTarget = window['XMLHttpRequestEventTarget'];

    if (XMLHttpRequestEventTarget) {
      var XMLHttpRequestEventTargetPrototype = XMLHttpRequestEventTarget.prototype;
      oriAddListener = XMLHttpRequestEventTargetPrototype[ADD_EVENT_LISTENER_STR];
      oriRemoveListener = XMLHttpRequestEventTargetPrototype[REMOVE_EVENT_LISTENER_STR];
    }
  }

  var READY_STATE_CHANGE = 'readystatechange';
  var LOAD = 'load';

  function invokeTask(task) {
    task.state = INVOKE;
    callback(INVOKE, task);
  }

  function scheduleTask(task) {
    XMLHttpRequest[XHR_SCHEDULED] = false;
    task.state = SCHEDULE;
    callback(SCHEDULE, task);
    var _task$data = task.data,
        aborted = _task$data.aborted,
        target = _task$data.target;

    if (!oriAddListener) {
      oriAddListener = target[ADD_EVENT_LISTENER_STR];
      oriRemoveListener = target[REMOVE_EVENT_LISTENER_STR];
    }

    var listener = target[XHR_LISTENER];

    if (listener) {
      oriRemoveListener.call(target, READY_STATE_CHANGE, listener);
      oriRemoveListener.call(target, LOAD, listener);
    }

    var earlierEvent;

    var newListener = target[XHR_LISTENER] = function (_ref) {
      var type = _ref.type;

      if (earlierEvent) {
        if (earlierEvent != type) {
          scheduleMacroTask(function () {
            if (task.state !== INVOKE) {
              invokeTask(task);
            }
          });
        }
      } else {
        if (target.readyState === target.DONE) {
          if (!aborted && XMLHttpRequest[XHR_SCHEDULED] && task.state === SCHEDULE) {
            earlierEvent = type;
          }
        }
      }
    };

    oriAddListener.call(target, READY_STATE_CHANGE, newListener);
    oriAddListener.call(target, LOAD, newListener);
    var storedTask = target[XHR_TASK];

    if (!storedTask) {
      target[XHR_TASK] = task;
    }
  }

  function clearTask(task) {
    task.state = CLEAR;
    callback(CLEAR, task);
    var data = task.data;
    data.aborted = true;
  }

  var openNative = patchMethod(XMLHttpRequestPrototype, 'open', function () {
    return function (self, args) {
      if (!self[XHR_IGNORE]) {
        self[XHR_METHOD] = args[0];
        self[XHR_URL] = args[1];
        self[XHR_SYNC] = args[2] === false;
      }

      return openNative.apply(self, args);
    };
  });
  var sendNative = patchMethod(XMLHttpRequestPrototype, 'send', function () {
    return function (self, args) {
      if (self[XHR_IGNORE]) {
        return sendNative.apply(self, args);
      }

      var task = {
        source: XMLHTTPREQUEST,
        state: '',
        type: 'macroTask',
        data: {
          target: self,
          method: self[XHR_METHOD],
          sync: self[XHR_SYNC],
          url: self[XHR_URL],
          aborted: false
        }
      };
      scheduleTask(task);
      var result = sendNative.apply(self, args);
      XMLHttpRequest[XHR_SCHEDULED] = true;

      if (self[XHR_SYNC]) {
        invokeTask(task);
      }

      return result;
    };
  });
  var abortNative = patchMethod(XMLHttpRequestPrototype, 'abort', function () {
    return function (self, args) {
      if (!self[XHR_IGNORE]) {
        var task = self[XHR_TASK];

        if (task && typeof task.type === 'string') {
          if (task.data && task.data.aborted) {
            return;
          }

          clearTask(task);
        }
      }

      return abortNative.apply(self, args);
    };
  });
}